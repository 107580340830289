import { Col, Form, Input, Row, Space, Select as AntdSelect, Tag, Typography, Tooltip } from "antd";
import React from "react";
import dayjs, { Dayjs } from "dayjs";
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  Amount,
  Button,
  ColumnType,
  DatePicker,
  EditButton,
  PageHeader,
  SearchButton,
  Table,
  TransactionActions,
} from "src/components";
import Icon from "src/components/Icon/Icon";
import { DepositModal, RefundRequestModal, AdjustTransactionModal, TransactionListModal } from "src/components/Modals";
import RefundModal from "src/components/Modals/RefundModal/RefundModal";
import WithdrawModal from "src/components/Modals/WithdrawModal/WithdrawModal";
import { useTransactions, TransactionsFilters } from "src/hooks";
import { DEPOSIT_STATUS, FILTER, Transaction, WITHDRAW_STATUS } from "src/hooks/useTransaction/type";
import { useTransactionState } from "src/hooks/useTransaction/useTransaction";
import { authInfoState } from "src/recoils";
import {
  BankCode,
  BankRow,
  DepositTransactionStatus,
  WithdrawTransactionStatus,
} from "src/types";
import { formatDate } from "src/utils/date";
import { formatDatetime } from "src/utils";
import { decodeAuthToken } from "src/utils/decode/decode";
import { TO_BE_DEPOSIT_STATUS, TO_BE_REFUND_STATUS, TO_BE_WITHDRAW_STATUS, TRANSACTION_COLOR, TRANSACTION_STATUS, TRANSACTION_TAG } from "src/mapper/mapToTransaction";
import { BankSelect } from "src/components/Bank/BankSelect";
import { useBank } from "src/hooks/useTransaction/useBank";
import './index.scss';
import { getColumnFilters } from "../../utils/table";
import BonusColumn from "src/components/TableColumns/BonusTypeColumn";

const INITIAL_DATERANGE: dayjs.Dayjs[] = [
  dayjs().hour(0).minute(0).second(0).millisecond(0),
  dayjs().hour(23).minute(59).second(59).millisecond(999),
];

type SearchForm = {
  adminBank?: BankRow;
  customerCode?: string;
  accountNumber?: string;
  status?: string[];
  datetimeRange: Dayjs[];
}

function SearchTransaction() {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<TransactionsFilters>({
    orderBy: 'transferred_at',
    searchBy: 'transferred_at',
  });
  const { token = '', role } = useRecoilValue(authInfoState);
  const { depositBankList, withdrawBankList, getWithdrawBankList } = useBank();
  const transactionState = useTransactionState({ filter: FILTER.NONE });
  const { reserveTransaction, cancelReserveTransaction, adjustingTransaction, depositingUser, getAllColumn } = transactionState;
  const { data, refetch, remove, isFetching, isError } = useTransactions(filters, {
    enabled: false,
    keepPreviousData: true,
  });

  const onFinish = (values: SearchForm) => {
    remove();
    const filterUndefined = (value: any) => value;
    const isFilteringStatus = values.status ? values.status.length > 0 : false;
    const depositStatuses = values.status && isFilteringStatus ? values.status.map((i: string) => TO_BE_DEPOSIT_STATUS[i]).filter(filterUndefined) : ["PENDING", "COMPLETED", "REJECTED", "CANCELLED"] as DepositTransactionStatus[];
    const refundStatuses = values.status && isFilteringStatus ? values.status.map((i: string) => TO_BE_REFUND_STATUS[i]).filter(filterUndefined) : ["TRANSFER_BACK", "WAITING_TRANSFER"] as DepositTransactionStatus[];
    const withdrawStatuses = values.status && isFilteringStatus ? values.status.map((i: string) => TO_BE_WITHDRAW_STATUS[i]).filter(filterUndefined) : ["PENDING", "COMPLETED", "REJECTED"] as WithdrawTransactionStatus[];

    setFilters({
      ...filters,
      page: 1,
      pageSize: 100,
      adminBankCode: values.adminBank ? values.adminBank.bankCode : undefined,
      adminBankAccountName: values.adminBank ? values.adminBank.accountName : undefined,
      accountNumber: values.adminBank ? values.adminBank.accountNumber : undefined,
      customerCode: values.customerCode ? values.customerCode : undefined,
      start: formatDatetime(values.datetimeRange[0]),
      end: formatDatetime(values.datetimeRange[1]),
      depositStatuses: depositStatuses as DepositTransactionStatus[],
      withdrawStatuses: withdrawStatuses as WithdrawTransactionStatus[],
      refundStatuses: refundStatuses as DepositTransactionStatus[],
    });
  };

  const group = form.getFieldValue(["group"]);
  const { remark, status, customerAccount, adminAccount, moreDetail, amount, transferedAtDate, transferedAtTime, bonus, isBonus } = getAllColumn();
  const columns: ColumnType<Transaction>[] = [
    {
      ...transferedAtDate,
      title: 'วันที่',
      render: (value: Date, record) => {
        return `${formatDate(record.transferAt)}`;
      },
    },
    {
      ...transferedAtTime,
      filters: getColumnFilters("transferAtTime", data?.results || []),
    },
    {
      ...adminAccount,
      title: "บัญชี PIGSPIN",
    },
    {
      ...isBonus,
      title: <div style={{ textAlign: 'center' }}>
        <Tooltip placement="top" title="รับโบนัส">
          <Button type="text" style={{ padding: 0 }}>
            <Icon name="outline-tag" size="1rem" />
          </Button>
        </Tooltip>
      </div>,
      render: (value, record) => {
        if (record.type === 'DEPOSIT' && record.status === DEPOSIT_STATUS.COMPLETED) {
          return <BonusColumn isBonus={record.isBonus} bonusType={record.bonusType} />
        } else {
          return "-"
        }
      },
    },
    {
      ...amount,
      title: "เติมเงิน",
      key: "depositAmount",
      exclude: group === 3 ? true : false,
      filters: getColumnFilters("amount", data?.results.filter((row) => row.type === 'DEPOSIT') || []),
      onFilter: (value, row) => row.amount === value && row.type === 'DEPOSIT',
      render: (_, record) => (
        <Amount value={record.type === 'DEPOSIT' ? record.amount : undefined} />
      ),
    },
    bonus,
    {
      ...amount,
      title: "ถอนเงิน",
      key: "withdrawAmount",
      exclude: group === 2 ? true : false,
      filters: getColumnFilters("amount", data?.results.filter((row) => row.type === 'WITHDRAW' || row.type === 'REFUND') || []),
      onFilter: (value, row) => row.amount === value && (row.type === 'WITHDRAW' || row.type === 'REFUND'),
      render: (_, record) => (
        <Amount value={record.type === 'WITHDRAW' || record.type === 'REFUND' ? record.amount : undefined} />
      ),
    },
    {
      ...status,
      filters: getColumnFilters("statusMessage", data?.results || []),
      width: 150,
    },
    {
      ...customerAccount,
      filters: getColumnFilters("customerBankNumber", data?.results || []),
    },
    {
      title: "รหัสลูกค้า",
      dataIndex: "userAccount",
      filters: getColumnFilters("userAccount", data?.results || []),
      width: 120,
      render: (value) => (value?.startsWith("PS") ? value : ""),
    },
    {
      ...remark,
      width: "15%",
    },
    moreDetail,
    {
      key: "action",
      width: 80,
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <div style={{ textAlign: 'left' }}>
          <Space>
            <TransactionActions transaction={record} onSuccess={() => refetch()} />
            {role !== 'ADMIN'
              && record.status === DEPOSIT_STATUS.COMPLETED
              && !(record.adminBank.bankCode === BankCode.NONE && record.customerBank.bankCode === BankCode.NONE)
              && (
                <EditButton size="middle" onClick={() => { reserveTransaction(record) }} />
              )}
          </Space>
        </div>
      ),
    },
  ];
  const bankList = React.useMemo(() => ([
    {
      label: 'บัญชีรับเงิน',
      options: depositBankList,
    },
    {
      label: 'บัญชีโอนเงิน',
      options: withdrawBankList,
    },
  ]), [depositBankList, withdrawBankList]);

  useEffect(() => {
    getWithdrawBankList();
  }, []);

  useEffect(() => {
    const authInfo = decodeAuthToken(token)
    if (adjustingTransaction) return;
    const myEditingTransaction = data?.results.find(_transaction => _transaction.editingBy === authInfo.user_uid);
    const updateMyEditingTransaction = async (_transaction: Transaction) => {
      cancelReserveTransaction(_transaction);
    };
    if (myEditingTransaction) updateMyEditingTransaction(myEditingTransaction);
  }, [data?.results])

  useEffect(() => {
    // NOTE : Prevent 1st call on landing
    if (filters.page === undefined && filters.pageSize === undefined) return;
    refetch();
  }, [adjustingTransaction, filters])

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={TRANSACTION_TAG[value]}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };


  return (
    <div id="SearchTransactionPage">
      <PageHeader>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            group: 1,
            datetimeRange: INITIAL_DATERANGE,
          }}
        >
          <Row className="SearchPanel" gutter={[8, 0]}>
            <Col span={4} lg={8} xl={8} xxl={8}>
              <Form.Item name="customerCode" label="รหัสหรือเบอร์โทร">
                <Input size="large" placeholder="ใส่ข้อมูล" allowClear />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="adminBank" label="บัญชี PIGSPIN">
                <BankSelect bankList={bankList} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="status" label="สถานะ">
                <AntdSelect
                  allowClear
                  size="large"
                  placeholder="ใส่สถานะ"
                  dropdownMatchSelectWidth
                  mode="multiple"
                  tagRender={tagRender}
                >
                  {[
                    { value: DEPOSIT_STATUS.PENDING, label: TRANSACTION_STATUS[DEPOSIT_STATUS.PENDING] },
                    { value: DEPOSIT_STATUS.REJECTED, label: TRANSACTION_STATUS[DEPOSIT_STATUS.REJECTED] },
                    { value: WITHDRAW_STATUS.PENDING, label: TRANSACTION_STATUS[WITHDRAW_STATUS.PENDING] },
                    { value: DEPOSIT_STATUS.WAITING_TRANSFER, label: TRANSACTION_STATUS[DEPOSIT_STATUS.WAITING_TRANSFER] },
                    { value: DEPOSIT_STATUS.TRANSFER_BACK, label: TRANSACTION_STATUS[DEPOSIT_STATUS.TRANSFER_BACK] },
                    { value: DEPOSIT_STATUS.COMPLETED, label: TRANSACTION_STATUS[DEPOSIT_STATUS.COMPLETED] },
                    { value: WITHDRAW_STATUS.COMPLETED, label: TRANSACTION_STATUS[WITHDRAW_STATUS.COMPLETED] },
                    { value: DEPOSIT_STATUS.CANCELLED, label: TRANSACTION_STATUS[DEPOSIT_STATUS.CANCELLED] },
                    { value: WITHDRAW_STATUS.REJECTED, label: TRANSACTION_STATUS[WITHDRAW_STATUS.REJECTED] },
                  ].map((item) => (
                    <AntdSelect.Option value={item.value} label={item.label}>
                      <Typography.Text type={TRANSACTION_COLOR[item.value] as any}>
                        {item.label}
                      </Typography.Text>
                    </AntdSelect.Option>
                  ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col span={9} lg={5} xl={5} xxl={5}>
              <Form.Item
                name="datetimeRange"
                label={
                  <Row style={{ width: "300px" }}>
                    <Col span={12}>{"วันเวลาเริ่มต้น"}</Col>
                    <Col span={12}>{"วันเวลาสิ้นสุด"}</Col>
                  </Row>
                }
              >
                <DatePicker.RangePicker
                  size="large"
                  allowClear={false}
                  format="DD/MM/YY HH:mm"
                  showTime={{ format: "HH:mm" }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ textAlign: 'right' }}>
              <Form.Item label={<></>}>
                <SearchButton size="large" htmlType="submit" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
      <Table
        className="backoffice-table"
        rowKey="uid"
        columns={columns.filter((column) => !column.exclude)}
        dataSource={isError ? [] : data?.results}
        loading={{
          size: "large",
          spinning: isFetching,
          style: { maxHeight: "calc(100vh - 310px)" },
        }}
        pagination={{
          total: data?.total,
          current: data?.page,
          pageSize: data?.pageSize,
          onChange: (page, pageSize) => setFilters({ ...filters, page, pageSize }),
        }}
      />
      {/* <TransactionListModal {...transactionState} /> */}
      <AdjustTransactionModal {...transactionState} />
      <DepositModal depositState={transactionState} byUserAccount={depositingUser?.userAccount} />
      <RefundRequestModal depositState={transactionState} />
      <WithdrawModal {...transactionState} />
      <RefundModal {...transactionState} />
    </div>
  );
}

export default SearchTransaction;
