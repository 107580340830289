import { useQuery, UseQueryOptions } from "react-query";
import { mapToTransaction } from "src/mapper/mapToTransaction";
import {
  DepositTransactionStatus,
  Pagination,
  PolymorphicTransaction,
  TransactionType,
  WithdrawTransactionStatus,
} from "src/types";
import { api } from "src/utils";
import { Transaction } from "./useTransaction/type";

export interface TransactionsFilters {
  page?: number;
  pageSize?: number;
  start?: string;
  end?: string;
  customerCode?: string;
  accountNumber?: string;
  types?: TransactionType[];
  depositStatuses?: DepositTransactionStatus[];
  withdrawStatuses?: WithdrawTransactionStatus[];
  transferredAt?: string;
  refundStatuses?: DepositTransactionStatus[];
  adminBankCode?: string
  adminBankAccountName?: string;
  customerBankAccountName?: string;
  transferType?: string;
  onlyMe?: boolean;
  isSent?: boolean;
  orderBy?: any;
  searchBy?: any; // transferred_at, created_at, latest_action_at
}

export function fetchTransactions(filters?: TransactionsFilters) {
  return api.get("payment/transactions", {
    params: { ...filters },
  });
}

export function useTransactions<T = Pagination<Transaction>>(
  filters?: TransactionsFilters,
  options?: UseQueryOptions<Pagination<Transaction>, unknown, T, any[]>,
) {
  return useQuery(
    ["transactions", filters],
    async () => {
      const response = await fetchTransactions(filters);
      return {
        ...response.data.data,
        results: response.data.data.results.map(mapToTransaction),
      };
    },
    options
  );
}
